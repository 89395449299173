<template>
    <v-container>
        <v-card class="ma-3" elevation="0">
            <v-card-title>
                Tâches global :
                <v-progress-linear :value="100*totalGlobalTodo/(users.length*globalTodo.length)" height="25" class="mt-2">
                    <strong v-text="calcAvg(totalGlobalTodo, users.length*globalTodo.length) + '%'"></strong>
                </v-progress-linear>
            </v-card-title>
            <v-card-text>
                <v-expansion-panels accordion>
                    <v-expansion-panel v-for="task in globalTodo">
                        <v-expansion-panel-header>
                            <div class="mr-2">
                                <h4 v-text="'(' + task.nbChecked + '/' + users.length + ') ' + task.name" class="mb-1"></h4>
                                <v-progress-linear :value="calcAvg(task.nbChecked, users.length)" height="15" :color="selectColor(task.nbChecked, users.length)" :dark="selectColor(task.nbChecked, users.length) == 'success'">
                                    <strong v-text="calcAvg(task.nbChecked, users.length) + '%'"></strong>
                                </v-progress-linear>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <table>
                                <tr v-for="user in users">
                                    <td>
                                        <UserCard :profile="user"></UserCard>
                                    </td>
                                    <td>
                                        <v-checkbox class="pl-3" disabled v-model="task.checked[user.id]"></v-checkbox>
                                    </td>
                                </tr>
                            </table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    import Task from '@/classes/Task.js'
    import Profile from '@/classes/Profile.js'

    import UserCard from '@/components/UserCard.vue'

    export default {
        name: 'TaskProgressionTab',
        props: ["user", "profile"],
        components: {
            UserCard
        },
        data() {
            return {
                globalTodo: [],
                totalGlobalTodo: 0,
                users: [],
                unsub: []
            }
        },
        created() {
            // récupération des users de la room
            this.unsub.push(Profile.listenByRoom(this.profile.currentRoom, (tmp_profiles) => {
                let userProfile = []
                for (let profile of tmp_profiles) {
                    if (profile.role == "User") userProfile.push(profile)
                }
                this.users = userProfile
            }))
            // récupération des tasks global
            this.unsub.push(Task.listenByRoomAndByType(this.profile.currentRoom, "global", (tmp_tasks) => {
                let totalNbCheck = 0
                for(const task of tmp_tasks){
                    let nbChecked = 0
                    for(const property in task.checked){
                        if(task.checked[property]) nbChecked++
                    }
                    task.nbChecked = nbChecked
                    totalNbCheck += nbChecked
                }
                this.globalTodo = tmp_tasks
                this.totalGlobalTodo = totalNbCheck
            }))
        },
        methods: {
            selectColor(amount, total){
                let percent = this.calcAvg(amount, total)
                if(percent >= 0 && percent < 25){
                    return "error"
                }else if(percent >= 25 && percent < 50){
                    return "warning"
                }else if(percent >= 50 && percent < 75){
                    return "info"
                }else{
                    return "success"
                }
            },
            calcAvg(amount, total){
                return total <= 0 ? 0 : Math.round((100*amount/total) * 100) / 100
            }
        },
        destroy() {
            this.unsub.forEach(unsub => unsub())
        }
    }
</script>