<template>
    <div v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
        <v-tabs v-model="tab" left>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-tab v-bind="attrs" v-on="on">
                        <v-icon>mdi-format-list-checks</v-icon>
                    </v-tab>
                </template>
                <span>Voir les tâches de la room</span>
            </v-tooltip>
            <v-tooltip bottom v-if="profile.role != 'User'">
                <template v-slot:activator="{ on, attrs }">
                    <v-tab v-bind="attrs" v-on="on">
                        <v-icon>mdi-google-analytics</v-icon>
                    </v-tab>
                </template>
                <span>Voir la progression</span>
            </v-tooltip>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <!-- Voir les tasks de la room -->
                <TaskListTab :profile="profile" :notifications="notifications"></TaskListTab>
            </v-tab-item>
            <v-tab-item v-if="profile.role != 'User'">
                <!-- Voir la progression -->
                <TaskProgressionTab :profile="profile"></TaskProgressionTab>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import TaskListTab from '@/components/Todo/TaskListTab'
    import TaskProgressionTab from '@/components/Todo/TaskProgressionTab'

    export default {
        name : "Todo",
		components: {
			TaskListTab,
            TaskProgressionTab
		},
		props: ["user", "profile", "notifications"],
        data() {
            return{
                tab: 0,
            }
        }
    }
</script>