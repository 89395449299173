<template>
    <div>
        <v-row>
            <v-col cols="12" :lg="profile.role != 'User' ? 4 : 6" v-if="globalTodo.length > 0">
                <v-card class="ma-3" elevation="0">
                    <v-card-title>Tâches global :</v-card-title>
                    <v-card-text>
                        <span v-for="task in globalTodo" v-if="task.date < new Date().getTime() || profile.role != 'User'" :class="'my-3 px-2 d-flex align-center rounded pointer '+((profile.role == 'User' &&task.checked[profile.id]) || task.allchecked ? 'darkBg' : 'lightBg')" @click="openTask(task)">
                            <v-badge bordered overlap color="notif" v-if="checkNotif(task.id)" :content="checkNotif(task.id)" class="v-badge-todo"></v-badge>
                            <span>
                                <v-checkbox @click.prevent.stop="task.saveCheck()" v-if="profile.role == 'User'" v-model="task.checked[profile.id]" color="info"></v-checkbox>
                                <v-checkbox @click.prevent.stop="task.saveCheck()" v-else v-model="task.allchecked" disabled color="info"></v-checkbox>
                            </span>
                            <span>
                                <h4 v-text="task.name" v-if="profile.role == 'User'" :class="task.checked[profile.id] ? 'text-decoration-line-through':''"></h4>
                                <h4 v-text="task.name" v-else :class="task.allchecked ? 'text-decoration-line-through':''"></h4>
                                <span v-text="task.desc" v-if="profile.role == 'User'" :class="task.checked[profile.id] ? 'text-decoration-line-through':''"></span>
                                <span v-text="task.desc" v-else :class="task.allchecked ? 'text-decoration-line-through':''"></span>
                            </span>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" :lg="profile.role != 'User' ? 4 : 6" v-if="personalTodo.length > 0">
                <v-card class="ma-3" elevation="0">
                    <v-card-title>Tâches personnel :</v-card-title>
                    <v-card-text>
                        <span v-for="task in personalTodo" :class="'my-3 px-2 d-flex align-center rounded pointer '+(task.checked ? 'darkBg' : 'lightBg')" @click="openTask(task)">
                            <v-badge bordered overlap color="notif" v-if="checkNotif(task.id)" :content="checkNotif(task.id)" class="v-badge-todo"></v-badge>
                            <v-checkbox @click.prevent.stop="task.saveCheck()" v-model="task.checked" color="primary"></v-checkbox>
                            <span>
                                <h4 v-text="task.name" :class="task.checked ? 'text-decoration-line-through':''"></h4>
                                <span v-text="task.desc" :class="task.checked ? 'text-decoration-line-through':''"></span>
                            </span>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" v-if="profile.role != 'User' && adminTodo.length > 0">
                <v-card class="ma-3" elevation="0" v-if="profile.role != 'User'">
                    <v-card-title>Tâches admin :</v-card-title>
                    <v-card-text>
                        <span v-for="task in adminTodo" :class="'my-3 px-2 d-flex align-center rounded pointer '+(task.checked ? 'darkBg' : 'lightBg')" @click="openTask(task)">
                            <v-badge bordered overlap color="notif" v-if="checkNotif(task.id)" :content="checkNotif(task.id)" class="v-badge-todo"></v-badge>
                            <v-checkbox @click.prevent.stop="task.saveCheck()" v-model="task.checked" color="error"></v-checkbox>
                            <span>
                                <h4 v-text="task.name" :class="task.checked ? 'text-decoration-line-through':''"></h4>
                                <span v-text="task.desc" :class="task.checked ? 'text-decoration-line-through':''"></span>
                            </span>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-col cols="12" class="mt-3 pa-3 d-flex justify-end">
            <v-btn color="primary" fab @click="openNew">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-col>

        <!-- pop up de création et de modification -->
        <v-dialog v-model="configModalDisplayed" width="1000" persistent>
            <v-card>
                <v-card-title v-if="configData">
                    <span class="text-h5" v-if="configData.type != 'global' || profile.role != 'User'">Configurer la tâche</span>
                    <span class="text-h5" v-else>Voir la tâche</span>
                </v-card-title>
                <v-card-text v-if="configData">
                    <v-container>
                        <v-row v-if="configData.type == 'global' && profile.role != 'User'">
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-progress-linear :value="calcAvg(configData.nbChecked, users.length)" height="20">
                                            <strong v-text="calcAvg(configData.nbChecked, users.length) + '% (' + configData.nbChecked + '/' + users.length + ')'"></strong>
                                        </v-progress-linear>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <table>
                                                <tr v-for="user in users">
                                                    <td>
                                                        <UserCard :profile="user"></UserCard>
                                                    </td>
                                                    <td>
                                                        <v-checkbox class="pl-3" disabled v-model="configData.checked[user.id]"></v-checkbox>
                                                    </td>
                                                </tr>
                                            </table>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-2">
                                <v-text-field label="Nom" v-model="configData.name" />
                            </v-col>
                            <v-col cols="12" class="py-2">
                                <v-textarea label="Description" v-model="configData.desc" rows="2"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-if="profile.role != 'User' && !configData.id">
                            <v-col cols="12" class="py-2">
                                <v-select v-model="configData.type" :items="visibilityLevels" item-text="name"
                                    item-value="value" label="Attribution"></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="profile.role != 'User'">
                            <v-col cols="12" md="6" class="py-2">
                                <v-text-field type="date" label="Date de publication" v-model="configData.date" />
                            </v-col>
                            <v-col cols="12" md="6" class="py-2">
                                <v-text-field type="time" label="Heure de publication" v-model="configData.time" />
                            </v-col>
                        </v-row>
                        <v-row class="d-flex align-center justify-center">
                            <v-btn class="mx-3" color="secondary" @click="close">Annuler</v-btn>
                            <v-btn class="mx-3" color="primary" @click="save" v-if="profile.role != 'User' || configData.type == 'perso'">Valider</v-btn>
                            <v-btn class="mx-3" color="error" @click="deleteTask" v-if="configData.id && (profile.role != 'User' || configData.type == 'perso')">Supprimer</v-btn>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
    .taskTitle{
        /* background: var(--v-lighter-base); */
        padding: 10px;
        border-radius: 15px;
        border-top: var(--v-lighter-base) solid 1px;
        border-left: var(--v-lighter-base) solid 1px;
        border-right: var(--v-lighter-base) solid 2px;
        border-bottom: var(--v-lighter-base) solid 2px;
        transition: 250ms;
        cursor: pointer;
        user-select: none;
    }
    .taskTitle:hover{
        background: var(--v-lighter-base);
    }
    *:has(> .v-badge-todo) {
        position: relative;
    }
    .v-badge-todo {
        position: absolute;
        top: 0;
        right: 0;
    }
</style>

<script>
import logger from "@/assets/functions/logger"
import notifier from "@/assets/functions/notifier"
import Swal from 'sweetalert2/dist/sweetalert2.js'

import Task from '@/classes/Task.js'
import Profile from '@/classes/Profile.js'

import UserCard from '@/components/UserCard.vue'

export default {
    name: 'TaskListTab',
    props: ["user", "profile", "notifications"],
    components: {
        UserCard
    },
    data() {
        return {
            personalTodo: [],
            globalTodo: [],
            adminTodo: [],
            configModalDisplayed: false,
            users: [],
            visibilityLevels: [
                {
                    name: "Tous",
                    value: "global"
                },
                {
                    name: "Admin",
                    value: "admin"
                },
                {
                    name: "Moi",
                    value: "perso"
                },
            ],
            unsub: [],
            configData: null
        }
    },
    created() {
        // récupération des tasks perso
        this.unsub.push(Task.listenByRoomByTypeAndByUser(this.profile.currentRoom, "perso", this.profile.id, (tmp_tasks) => {
            this.personalTodo = tmp_tasks
        }))
        // récupération des tasks global
        this.unsub.push(Task.listenByRoomAndByType(this.profile.currentRoom, "global", (tmp_tasks) => {
            for (const task of tmp_tasks) {
                task.allchecked = this.verifyAllCheck(task)

                if(this.profile.role != 'User'){
                    let nbChecked = 0
                    for(const property in task.checked){
                        if(task.checked[property]) nbChecked++
                    }
                    task.nbChecked = nbChecked
                }
            }
            this.globalTodo = tmp_tasks
        }))

        if (this.profile.role != 'User') {
            // récupération des tasks admin
            this.unsub.push(Task.listenByRoomAndByType(this.profile.currentRoom, "admin", (tmp_tasks) => {
                this.adminTodo = tmp_tasks
            }))

            // récupération des users de la room
            this.unsub.push(Profile.listenByRoom(this.profile.currentRoom, (tmp_users) => {
                let userProfile = []
                for (let profile of tmp_users) {
                    if (profile.role == "User") userProfile.push(profile)
                }
                this.users = userProfile
                this.refreshGlobalTodo()
            }))
        }
    },
    methods: {
        /* NOTIFICATIONS */
        checkNotif(id){
            if (this.notifications && this.notifications.todo) {
                for (const notif of this.notifications.todo) {
                    if (id === notif.content) return 1
                }
                return 0
            }
            return 0
        },
        deleteNotif(id){
            if (this.notifications && this.notifications.todo) {
                for (const notif of this.notifications.todo) {
                    if (id === notif.content) {
                        notif.delete()
                        return
                    }
                }
            }
        },

        openNew() {
            this.configData = {}
            this.configData.id = null
            this.configData.type = "perso"
            this.configData.date = new Date().toISOString().slice(0, 10)
            this.configData.time = new Date().toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 5)
            this.configData.name = null
            this.configData.desc = null
            this.configModalDisplayed = true
        },
        openTask(selectedTask) {
            this.deleteNotif(selectedTask.id)

            this.configData = {}
            this.configData.id = selectedTask.id
            this.configModalDisplayed = true
            this.configData.name = selectedTask.name
            this.configData.desc = selectedTask.desc
            this.configData.date = new Date(selectedTask.date).toISOString().slice(0, 10)
            this.configData.time = new Date(selectedTask.date).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' })
            this.configData.type = selectedTask.type
            this.configData.checked = selectedTask.checked
            this.configData.nbChecked = selectedTask.nbChecked
            this.configData.task = selectedTask
        },
        close() {
            this.configModalDisplayed = false
            this.advanceDisplayed = false
            this.configData = null
        },
        async save() {
            if (this.configData.id) {
                this.configData.task.name = this.configData.name
                this.configData.task.desc = this.configData.desc
                this.configData.task.date = new Date(this.configData.date + " " + this.configData.time).getTime()

                this.configData.task.save().then(() => {
                    logger.log(this.profile.id, "UPDATE TASK", this.profile.email + " a modifié une tâche " + this.configData.task.type + " (" + this.configData.task.name + ")")
                    if (this.configData.task.type === 'global') notifier.send(this.profile.currentRoom,"todo",this.configData.task.date,this.configData.id)
                })
            } else {
                let tmp_checked = false
                if (this.configData.type == "global") tmp_checked = {}
                let tmp_task = new Task(null, this.profile.currentRoom, this.configData.type, new Date(this.configData.date + " " + this.configData.time).getTime(), this.configData.name, this.configData.desc, tmp_checked, this.profile.id)

                tmp_task.save().then(() => {
                    logger.log(this.profile.id, "ADD TASK", this.profile.email + " a ajouté une tâche")
                    if (tmp_task.type === 'global') notifier.send(this.profile.currentRoom,"todo",tmp_task.date,tmp_task.id)
                })
            }
            this.configModalDisplayed = false
            this.configData = null
        },
        deleteTask() {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    logger.log(this.profile.id, "DELETE TASK", this.profile.email + " a supprimé une tâche " + this.configData.task.type + " (" + this.configData.task.name + ")")
                    this.configData.task.delete()
                    this.configModalDisplayed = false
                    this.configData = null
                }
            })
        },
        refreshGlobalTodo(){
            for (const task of this.globalTodo) {
                task.allchecked = this.verifyAllCheck(task)

                if(this.profile.role != 'User'){
                    let nbChecked = 0
                    for(const property in task.checked){
                        if(task.checked[property]) nbChecked++
                    }
                    task.nbChecked = nbChecked
                }
            }
        },
        verifyAllCheck(task) {
            if (this.users.length == Object.keys(task.checked).length) {
                for (let profileId in task.checked) {
                    if (!task.checked[profileId]) {
                        return false
                    }
                }
                return true
            } else {
                return false
            }
        },
        calcAvg(amount, total){
            return Math.round((100*amount/total) * 100) / 100
        }
    },
    destroy() {
        this.unsub.forEach(unsub => unsub())
    }
}
</script>